<template>
    <v-container fluid>
        <v-row justify="center" align="center" v-if="notice != null">
            <v-card id="request_header" style="border-radius:10px;" :width="isMobile() ? '100%' : '70%'"
                :class="isMobile() ? 'px-2' : 'mt-10 mb-15'" elevation="2">
                <v-card-subtitle v-if="end_request" style="font-size:24px;padding:50px;color:black;font-weight:500;">
                    Thank you for submitting your information. We will be in contact soon.
                </v-card-subtitle>
                <v-card-subtitle v-if="start_request && !end_request"
                    style="font-size:18px;white-space: pre-line;word-break: break-word;" class="mb-5">
                    <v-tabs :show-arrows="isMobile() ? false : true" :value="tab" icons-and-text
                        style="display:flex;justify-content:center;" class="mb-5">
                        <v-tabs-slider style="display:none;"></v-tabs-slider>
                        <v-tab v-for="(nav, index) in notice_tabs" :key="index" :href="`#notice_tab-${index}`"
                            style="text-transform: none !important;border-radius:16px;"
                            :class="{ active: `notice_tab-${index}` == tab }" @click="change_tab(index)">
                            <p v-if="!isMobile()"
                                :style="isMobile() ? 'width:70px;font-size:12px;' : 'width:120px;font-size:15px;'">
                                <label
                                    style="white-space:pre-line;word-break:break-word;padding-top:20px;cursor:pointer;">
                                    {{ nav.label }}
                                </label>
                            </p>
                            <v-avatar :size="isMobile() ? 55 : 35" color="#172C54" style="position: relative;">
                                <strong style="font-size: 22px;color:white;">{{ index + 1 }}</strong>
                            </v-avatar>
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items :value="tab" style="width: 100%;">
                        <v-tab-item value="notice_tab-0">
                            <h3 v-if="isMobile()" style="text-align:center;margin-bottom:20px;">Contact Details</h3>
                            <v-row :class="isMobile() ? '' : 'px-5'">
                                <v-col cols="12">
                                    <v-btn color="#172C54" dark style="float:right;" @click="change_tab(1)">Next</v-btn>
                                </v-col>
                                <v-col cols="12">Your information / Representative of the deceased</v-col>
                                <v-col :cols="isMobile() ? 12 : 6">
                                    <v-text-field label="Representative's First Name *" outlined dense
                                        v-model="user_info.representative_firstname">
                                    </v-text-field>
                                    <v-text-field label="Representative's Last Name *" outlined dense
                                        v-model="user_info.representative_lastname">
                                    </v-text-field>
                                </v-col>
                                <v-col :cols="isMobile() ? 12 : 6">
                                    <v-text-field label="Representative's Email" outlined dense
                                        v-model="user_info.email">
                                    </v-text-field>
                                    <v-text-field label="Representative's Phone Number" outlined dense
                                        v-model="user_info.mobile">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">Details of the Deceased</v-col>
                                <v-col :cols="isMobile() ? 12 : 6">
                                    <v-text-field label="First Name *" outlined dense v-model="user_info.firstname">
                                    </v-text-field>
                                    <v-text-field label="Last Name *" outlined dense v-model="user_info.lastname">
                                    </v-text-field>
                                    <!-- <v-text-field label="Maiden Name" outlined dense v-model="user_info.maiden_name">
                                    </v-text-field> -->
                                </v-col>
                                <v-col :cols="isMobile() ? 12 : 6">
                                    <v-select :items="['his', 'her', 'their']" outlined dense label="Pronoun"
                                        v-model="user_info.pronoun">
                                    </v-select>
                                    <!-- <v-text-field outlined dense v-model="user_info.date_of_death"
                                        label="Date of Death"></v-text-field> -->
                                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field outlined dense v-model="user_info.date_of_death"
                                                label="Date of Death" prepend-icon="mdi-calendar" readonly
                                                v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="user_info.date_of_death" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu = false">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                    <v-text-field label="Birth Surname" outlined dense v-model="user_info.maiden_name">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">Deceased's Home Address</v-col>
                                <v-col :cols="isMobile() ? 12 : 6">
                                    <v-text-field label="Street Address" outlined dense v-model="user_info.address">
                                    </v-text-field>
                                    <v-text-field label="Townland" outlined dense v-model="user_info.town">
                                    </v-text-field>
                                    <v-text-field label="Town/City" outlined dense v-model="user_info.city">
                                    </v-text-field>
                                </v-col>
                                <v-col :cols="isMobile() ? 12 : 6">
                                    <v-select label="County" :items="counties" outlined dense
                                        v-model="user_info.county">
                                    </v-select>
                                    <v-text-field label="Eircode" outlined dense v-model="user_info.eircode">
                                    </v-text-field>
                                    <v-text-field label="Former Address" outlined dense
                                        v-model="user_info.former_address">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="#172C54" dark style="float:right;" @click="change_tab(1)">Next</v-btn>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item value="notice_tab-1">
                            <h3 v-if="isMobile()" style="text-align:center;margin-bottom:20px;">Funeral Notice
                                Information</h3>
                            <v-row :class="isMobile() ? '' : 'px-5'">
                                <v-col cols="12">
                                    <v-btn color="#172C54" dark @click="change_tab(0)">Back</v-btn>
                                    <v-btn color="#172C54" dark style="float:right;" @click="change_tab(2)">Next</v-btn>
                                </v-col>
                                <v-col cols="12">Details of the Deceased</v-col>
                                <v-col :cols="isMobile() ? 12 : 6">
                                    <v-text-field label="Circumstances" outlined dense
                                        v-model="user_info.circumstance_of_death">
                                    </v-text-field>
                                    <small style="color:black;margin-top:-25px;display:block;">E.g. peacefully,
                                        suddenly,
                                        surrounded by family</small>
                                </v-col>
                                <v-col :cols="isMobile() ? 12 : 6">
                                    <v-text-field label="Place of Death" outlined dense
                                        v-model="user_info.place_of_death">
                                    </v-text-field>
                                    <v-text-field label="Age" outlined dense v-model="user_info.age">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">Predeceased Family Members</v-col>
                                <v-col cols="12">
                                    <v-text-field label="Predeceased Family Members" outlined dense
                                        v-model="family_info.predeceased_by">
                                    </v-text-field>
                                    <small style="color:black;margin-top:-25px;display:block;">E.g. partners, parents,
                                        brothers, sisters, children</small>
                                </v-col>
                                <v-col cols="12">Surviving Family Members</v-col>
                                <v-col cols="12">
                                    <v-select label="Partner/Spouse" :items="['husband', 'wife', 'partner']" outlined
                                        dense v-model="family_info.significant_other_type">
                                    </v-select>
                                    <v-text-field label="Partner/Spouse Name" outlined dense
                                        v-model="family_info.significant_other">
                                    </v-text-field>
                                    <v-text-field label="Father" outlined dense v-model="family_info.father">
                                    </v-text-field>
                                    <v-text-field label="Mother" outlined dense v-model="family_info.mother">
                                    </v-text-field>
                                    <v-text-field label="Sons" outlined dense v-model="family_info.sons">
                                    </v-text-field>
                                    <v-text-field label="Daughters" outlined dense v-model="family_info.daughters">
                                    </v-text-field>
                                    <v-text-field label="Children" outlined dense v-model="family_info.children">
                                    </v-text-field>
                                    <v-text-field label="Brothers" outlined dense v-model="family_info.brothers">
                                    </v-text-field>
                                    <v-text-field label="Sisters" outlined dense v-model="family_info.sisters">
                                    </v-text-field>
                                    <v-text-field label="Grandchildren" outlined dense
                                        v-model="family_info.grandchildren">
                                    </v-text-field>
                                    <v-text-field label="Sons-in-law" outlined dense v-model="family_info.sons_in_law">
                                    </v-text-field>
                                    <v-text-field label="Daughters-in-law" outlined dense
                                        v-model="family_info.daughters_in_law">
                                    </v-text-field>
                                    <v-text-field label="Great grandchildren" outlined dense
                                        v-model="family_info.greatgrandchildren">
                                    </v-text-field>
                                    <v-text-field label="Other Relevant People to Mention" outlined dense
                                        v-model="family_info.other_people">
                                    </v-text-field>
                                    <div class="mb-4">
                                        <input type="checkbox" v-model="family_info.brothers_in_law" />
                                        Brothers-in-law
                                    </div>
                                    <div class="mb-4">
                                        <input type="checkbox" v-model="family_info.sisters_in_law" />
                                        Sisters-in-law
                                    </div>
                                    <div class="mb-4">
                                        <input type="checkbox" v-model="family_info.nephews" />
                                        Nephews
                                    </div>
                                    <div class="mb-4">
                                        <input type="checkbox" v-model="family_info.nieces" />
                                        Nieces
                                    </div>
                                </v-col>
                                <v-col cols="12">Photograph for Funeral Notice</v-col>
                                <v-col :cols="isMobile() ? 12 : 6">
                                    <v-file-input label="Choose photograph" outlined dense
                                        v-model="user_info.funeral_notice_image">
                                    </v-file-input>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="#172C54" dark @click="change_tab(0)">Back</v-btn>
                                    <v-btn color="#172C54" dark style="float:right;" @click="change_tab(2)">Next</v-btn>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item value="notice_tab-2">
                            <h3 v-if="isMobile()" style="text-align:center;margin-bottom:20px;">Public Arrangements
                            </h3>
                            <v-row class="px-5">
                                <v-col cols="12">
                                    <v-btn color="#172C54" dark @click="change_tab(1)">Back</v-btn>
                                    <v-btn color="#172C54" dark style="float:right;" @click="generate_funeral_notice"
                                        v-if="!saving">Submit</v-btn>
                                    <v-btn v-else disabled style="float:right;">Submitting</v-btn>
                                </v-col>
                                <v-col cols="12" style="font-size:14px;white-space:pre-line;word-break:break-word;">
                                    The following options tell the members of the public what the public arrangements
                                    for the funeral are. We have written “select one” but selecting none is also
                                    allowed, as the details may not be finalised yet.
                                </v-col>
                                <v-col cols="12">
                                    Ceremony (select one)
                                    <br>
                                    <v-radio-group v-model="ceremony_type">
                                        <v-radio label="Religious" value="Religious"></v-radio>
                                        <v-radio label="Civil / Non religious" value="Civil / Non religious"></v-radio>
                                        <v-radio label="No ceremony / private ceremony"
                                            value="No ceremony / private ceremony"></v-radio>
                                    </v-radio-group>
                                    <v-text-field
                                        v-if="ceremony_type == 'Religious' || ceremony_type == 'Civil / Non religious'"
                                        v-model="ceremony_date" placeholder="Preferred Date (eg 13th February 2025)"></v-text-field>
                                    <v-text-field
                                        v-if="ceremony_type == 'Religious' || ceremony_type == 'Civil / Non religious'"
                                        v-model="ceremony_time" placeholder="Preferred Time"></v-text-field>
                                    <v-text-field
                                        v-if="ceremony_type == 'Religious' || ceremony_type == 'Civil / Non religious'"
                                        v-model="ceremony_location" placeholder="Preferred Location"></v-text-field>
                                    <br>
                                    Burial / Cremation
                                    <br>
                                    <v-radio-group v-model="funeral_type">
                                        <v-radio label="Burial" value="Burial"></v-radio>
                                        <v-radio label="Cremation" value="Cremation"></v-radio>
                                    </v-radio-group>
                                    <v-text-field v-if="funeral_type != null" v-model="funeral_location"
                                        placeholder="Preferred Location"></v-text-field>
                                    <label v-if="funeral_type == 'Cremation'">Private Cremation</label>
                                    <v-radio-group v-if="funeral_type == 'Cremation'" v-model="funeral_private">
                                        <v-radio label="Yes" value="Yes"></v-radio>
                                        <v-radio label="No" value="No"></v-radio>
                                    </v-radio-group>
                                    <br>
                                    Public Visitation / Viewing / Reposing / Wake (select one)
                                    <br>
                                    <v-radio-group v-model="wake_type">
                                        <v-radio label="Public wake / viewing for set times"
                                            value="Public wake"></v-radio>
                                        <v-radio label="Private" value="Private"></v-radio>
                                        <v-radio label="No wake / viewing" value="No wake"></v-radio>
                                    </v-radio-group>
                                    <v-text-field v-if="wake_type == 'Public wake'" v-model="wake_time"
                                        placeholder="from xx AM/PM to xx AM/PM"></v-text-field>
                                    <v-text-field v-if="wake_type == 'Public wake'" v-model="wake_date"
                                        placeholder="Preferred date (eg 13th February 2025)"></v-text-field>
                                    <br>
                                    Location (select one)
                                    <br>
                                    <v-radio-group v-model="wake_location">
                                        <v-radio label="Family home" value="Family home"></v-radio>
                                        <v-radio label="Funeral home" value="Funeral home"></v-radio>
                                    </v-radio-group>
                                    <v-text-field v-if="wake_location == 'Family home'" v-model="wake_specific_location"
                                        placeholder="Address"></v-text-field>
                                    <v-text-field v-if="wake_location == 'Family home'" v-model="user_info.eircode"
                                        placeholder="Eircode"></v-text-field>
                                    <v-text-field v-if="wake_location == 'Funeral home'"
                                        v-model="wake_specific_location"
                                        placeholder="Funeral Home Location"></v-text-field>
                                    <br>
                                    Other Funeral Notice Text Options
                                    <br>
                                    <v-checkbox v-model="other_family_flowers" label="Family Flowers Only"
                                        :value="true" />
                                    <v-checkbox v-model="other_charity_donations" label="Donations to a charity"
                                        :value="true" />
                                    <v-checkbox v-model="other_live_streaming" label="Live Funeral Streaming"
                                        :value="true" />
                                    <br>
                                    Any other optional text to add to the funeral notices
                                    <br>
                                    <v-text-field v-model="other_message"
                                        placeholder="Nickname, small obituary about the deceased, favourite verse, favourite saying"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="#172C54" dark @click="change_tab(1)">Back</v-btn>
                                    <v-btn color="#172C54" dark style="float:right;" @click="generate_funeral_notice"
                                        v-if="!saving">Submit</v-btn>
                                    <v-btn v-else disabled style="float:right;">Submitting</v-btn>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-subtitle>
                <v-card-subtitle v-else-if="!end_request">
                    <p>Welcome to our Funeral Notice Form. You can fill out some details here that will help us
                        to write the funeral notice / obituary for your loved one and ultimately help to organise their
                        funeral. Feel free to skip any sections that you feel are not relevant to you.</p>
                    <p>Please note that we will put the finishing touches on this notice before it is
                        published. The information that you submit will be stored securely and used appropriately in
                        keeping with our Data Protection Policy.</p>
                    <p>Feel free to contact us if you have any questions.</p>
                    <p>{{ notice.get_funeral_director.name }}</p>
                    <p>{{ notice.get_funeral_director.company_name }}</p>
                    <v-layout justify-center>
                        <v-btn @click="funeral_sheet()" color="#172C54" dark>Start Here</v-btn>
                    </v-layout>
                </v-card-subtitle>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
const Mustache = require('mustache');
export default {
    components: {
    },
    props: [
    ],
    data: () => ({
        img_url: null,
        notice: null,
        start_request: false,
        end_request: false,
        tab: 'notice_tab-0',
        notice_tabs: [
            { label: "Contact Details" },
            { label: "Funeral Notice Information" },
            { label: "Public Arrangements" },
        ],
        counties: [
            'Antrim', 'Armagh', 'Carlow', 'Cavan', 'Clare', 'Cork', 'Derry', 'Donegal', 'Down', 'Dublin', 'Fermanagh', 'Galway', 'Kerry', 'Kildare', 'Kilkenny', 'Laois', 'Leitrim', 'Limerick', 'Longford', 'Louth', 'Mayo', 'Meath', 'Monaghan', 'Offaly', 'Roscommon', 'Sligo', 'Tipperary', 'Tyrone', 'Waterford', 'Westmeath', 'Wexford', 'Wicklow',
        ],
        menu: false,
        funeral_notice_image: null,
        funeral_notice: null,
        user_info: {
            fd_id: null,
            id: null,
            name: null,
            firstname: null,
            lastname: null,
            maiden_name: null,
            birth_surname: null,
            representative_firstname: null,
            representative_lastname: null,
            email: null,
            mobile: null,
            pronoun: null,
            address: null,
            former_address: null,
            country: null,
            county: null,
            city: null,
            town: null,
            eircode: null,
            age: null,
            date_of_death: null,
            circumstance_of_death: null,
            place_of_death: null,
            funeral_notice_image: null,
            family: null,
            data: null,
        },
        family_info: {
            significant_other_type: null,
            significant_other: null,
            father: null,
            mother: null,
            brothers: null,
            sisters: null,
            children: null,
            sons: null,
            daughters: null,
            sons_in_law: null,
            daughters_in_law: null,
            grandchildren: null,
            greatgrandchildren: null,
            other_people: null,
            predeceased_by: null,
            brothers_in_law: false,
            sisters_in_law: false,
            nephews: false,
            nieces: false,
        },
        funeral_notice_template: null,
        saving: false,
        wake_type: null,
        wake_time: null,
        wake_date: null,
        wake_location: null,
        wake_specific_location: null,
        ceremony_type: null,
        ceremony_date: null,
        ceremony_time: null,
        ceremony_location: null,
        funeral_type: null,
        funeral_location: null,
        funeral_private: false,
        other_family_flowers: false,
        other_charity_donations: false,
        other_live_streaming: false,
        other_message: null,
    }),
    async mounted() {
        await this.get_data(this.$router.currentRoute.params.token)
    },
    created() {
        this.img_url = process.env.VUE_APP_API_URL
    },
    computed: {
        formattedDateOfDeath() {
            return this.user_info.date_of_death == null ? null : this.$date(this.user_info.date_of_death).format('DD-MM-YYYY')
        },
    },
    methods: {
        showSnackBar(message) {
            this.$store.commit('auth/setMessage',
                { show: true, message: message },
                { root: 1 })
        },
        async get_data(data) {
            if (this.$router.currentRoute.name == 'Funeral Request New') {
                const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
                let result = 0

                for (let i = 0; i < data.length; i++) {
                    result = result * 62 + chars.indexOf(data[i])
                }

                let id = result.toString().substring(0, result.toString().length - 6)
                console.log(id)
                await this.$axios.get('/non_auth/decode_funeral_request_link_new', { data: id })
                    .then(({ data }) => {
                        if (data.response) {
                            this.$store.dispatch('funeral_request/set_funeral_director', data.data.get_funeral_director)
                            this.$store.dispatch('funeral_request/set_fd_logo', data.data.get_funeral_director.company_logo)
                            this.notice = data.data
                            this.user_info.fd_id = this.notice.user_id
                        }
                    })
            }
            else {
                await this.$axios.get('/non_auth/decode_funeral_request_link', { data: data })
                    .then(({ data }) => {
                        if (data.response) {
                            this.$store.dispatch('funeral_request/set_funeral_director', data.data.get_funeral_director)
                            this.notice = data.data
                            this.user_info.fd_id = this.notice.user_id
                        }
                    })
            }
        },
        async change_tab(index) {
            this.$vuetify.goTo('#request_header', { offset: 50 });
            this.tab = 'notice_tab-' + index
            if (index != 2) {
                this.tab = 'notice_tab-' + index
            }
        },
        funeral_sheet() {
            this.start_request = true
            this.$vuetify.goTo('#request_header', { offset: 50 });
        },
        async generate_funeral_notice() {
            this.user_info.name = this.user_info.firstname + ' ' + this.user_info.lastname
            this.user_info.family = this.family_info
            this.user_info.date_of_death
            this.user_info.data = {
                wake_type: this.wake_type,
                wake_time: this.wake_time,
                wake_date: this.wake_date,
                wake_location: this.wake_location,
                wake_specific_location: this.wake_specific_location,
                ceremony_type: this.ceremony_type,
                ceremony_date: this.ceremony_date,
                ceremony_time: this.ceremony_time,
                ceremony_location: this.ceremony_location,
                funeral_type: this.funeral_type,
                funeral_location: this.funeral_location,
                funeral_private: this.funeral_private,
                other_family_flowers: this.other_family_flowers,
                other_charity_donations: this.other_charity_donations,
                other_live_streaming: this.other_live_streaming,
                other_message: this.other_message,
            }
            console.log(this.user_info)
            let payload = { fd_id: this.notice.user_id }
            await this.$axios.get('/non_auth/get_active_template', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.funeral_notice_template = data.data
                        console.log(this.funeral_notice_template.template)
                        this.funeral_notice = Mustache.render(this.funeral_notice_template.template, this.user_info)
                        this.save_funeral_notice()
                    }
                })
        },
        async save_funeral_notice() {
            this.saving = true
            this.user_info.family = this.family_info
            let payload = {
                user: this.user_info,
                wake_type: this.wake_type,
                wake_time: this.wake_time,
                wake_date: this.wake_date,
                wake_location: this.wake_location,
                wake_specific_location: this.wake_specific_location,
                ceremony_type: this.ceremony_type,
                ceremony_date: this.ceremony_date,
                ceremony_time: this.ceremony_time,
                ceremony_location: this.ceremony_location,
                funeral_type: this.funeral_type,
                funeral_location: this.funeral_location,
                funeral_private: this.funeral_private,
                other_family_flowers: this.other_family_flowers,
                other_charity_donations: this.other_charity_donations,
                other_live_streaming: this.other_live_streaming,
                other_message: this.other_message,
            }
            console.log(payload)
            await this.$axios.post('/non_auth/save_funeral_request', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.save_funeral_notice_image(data.data.id)
                    }
                    else {
                        this.showSnackBar(data.message)
                        this.saving = false
                    }
                })
        },
        async save_funeral_notice_image(user_id) {
            const formData = new FormData()
            formData.append('file', this.user_info.funeral_notice_image)
            formData.append('user_id', user_id)
            await this.$axios.post('/non_auth/edit_funeral_notice_image', formData)
                .then(({ data }) => {
                    if (data.response) {
                        this.save_funeral_notice_actual(user_id)
                    }
                })
        },
        async save_funeral_notice_actual(user_id) {
            let payload = {
                user_id: user_id,
                template_id: this.funeral_notice_template.id,
                notice: this.funeral_notice,
            }
            await this.$axios.post('/non_auth/save_funeral_notice', payload)
                .then(({ data }) => {
                    if (data.response) {
                        this.end_request = true
                    }
                })
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    }
}
</script>

<style scoped lang="scss">
::v-deep .v-tabs--icons-and-text>.v-tabs-bar {
    height: 120px;
}

::v-deep .v-slide-group__prev {
    display: none !important;
}

.active {
    background-color: #DFE9FF;
}
</style>